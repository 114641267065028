<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="modal-select2"
      title="Section"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="create"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <required-text-input-field
              v-model="additionalOptionSection.title"
              name="title"
              label="Titre"
              label-for="title"
              placeholder="Titre"
            />
          </b-col>

          <b-col md="6" class="d-flex flex-column justify-content-center">
            <b-form-checkbox
              v-model="additionalOptionSection.is_visible"
              name="is_visible"
              switch
              inline
            >
              Publié
            </b-form-checkbox>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="description"
              description="Limite de 255 caractères"
            >
              <b-form-textarea
                rows="4"
                size="sm"
                maxlength="255"
                v-model="additionalOptionSection.description"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { ValidationObserver } from "vee-validate";

import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";
import { required } from "@validations";
import { reactive } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

export default {
  components: {
    BAlert,
    BModal,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationObserver,
    RequiredTextInputField,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    section: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
    },
  },
  setup(props, { emit }) {
    let initialState = {
      title: null,
      description: null,
      is_visible: true,
    };

    const additionalOptionSection = reactive({ ...initialState });

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(additionalOptionSection, props.section);
      } else {
        Object.assign(additionalOptionSection, initialState);
      }
    };

    const create = async () => {
      try {
        if (props.action === "update") {
          emit("edit", additionalOptionSection);
        } else {
          emit("create", additionalOptionSection);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const { refFormObserver } = useValidatorsWithAutoFormDataCleanUp(
      additionalOptionSection
    );

    return {
      create,
      additionalOptionSection,
      fillDataIfProvided,
      refFormObserver,
      required,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
