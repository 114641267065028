<template>
  <GroupAdditionalOptions v-if="group" :group-id="group.id" />
</template>

<script>
import GroupAdditionalOptions from "./GroupAdditionalOptions/GroupAdditionalOptionsIndex.vue";

export default {
  components: { GroupAdditionalOptions },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
  },
};
</script>
