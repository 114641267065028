<template>
  <b-row v-if="isCurrentUserAdmin" class="match-height">
    <b-col lg="12" md="12">
      <div v-if="sections.length === 0" class="mt-4">
        <b-card
          title="Ajouter des sections de choix supplémentaires"
          header-tag="header"
          body-class="text-center"
          class="mx-5"
        >
          <b-card-text>
            Créer une ou plusieurs options supplémentaires pour votre groupe et
            ajouter vos inventaires.
          </b-card-text>

          <b-button
            variant="primary"
            v-if="canEdit"
            @click="showAddSectionModal()"
          >
            + Ajouter
          </b-button>
        </b-card>
      </div>
      <div class="mt-2" v-if="sections.length > 0">
        <b-button
          variant="success"
          class="float-right"
          v-if="canEdit"
          @click="showAddSectionModal()"
        >
          + Ajouter une section
        </b-button>
      </div>
      <div v-for="(section, sectionIndex) in sections" :key="section.id">
        <b-card
          border-variant="primary"
          header="Primary"
          header-bg-variant="primary"
          align="center"
          header-tag="header"
          class="mt-2"
          no-body
        >
          <template #header>
            <div class="d-flex flex-row">
              <h3 class="text-white">
                {{ sectionIndex + 1 }}. {{ section.title }}
              </h3>
              <span class="ml-50" v-if="!section.is_visible">
                <b-badge variant="light-secondary">Brouillon </b-badge>
              </span>
            </div>

            <div>
              <b-button-group>
                <b-button
                  variant="light"
                  v-if="canEdit"
                  @click="showEditSectionModal(sectionIndex)"
                  >Modifier</b-button
                >
                <b-button
                  v-if="canEdit"
                  variant="outline-light"
                  @click="cloneSection(section.id)"
                  >Dupliquer</b-button
                >
                <b-button
                  v-if="canEdit"
                  variant="outline-light"
                  @click="deleteSection(sectionIndex)"
                  >Supprimer</b-button
                >
                <b-button
                  v-b-toggle="'collapse-' + sectionIndex"
                  variant="outline-light"
                  >Voir plus</b-button
                >
              </b-button-group>

              <b-button-group class="ml-2">
                <b-button
                  variant="outline-light"
                  size="sm"
                  @click="moveSection(section.id, 'up')"
                  :disabled="sectionIndex === 0"
                >
                  <feather-icon size="18" icon="ArrowUpIcon" />
                </b-button>
                <b-button
                  variant="outline-light"
                  size="sm"
                  @click="moveSection(section.id, 'down')"
                  :disabled="sectionIndex === sections.length - 1"
                >
                  <feather-icon size="18" icon="ArrowDownIcon" />
                </b-button>
              </b-button-group>
            </div>
          </template>
          <b-collapse :id="'collapse-' + sectionIndex" visible>
            <b-row v-if="section.description">
              <b-col cols="12" class="text-align-left">
                <div class="section-description">
                  <h5>Description</h5>
                  <b-card-text class="description">
                    {{ section.description }}
                  </b-card-text>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div
                  class="p-2"
                  v-if="section.questions && section.questions.length == 0"
                >
                  <h3 class="text-muted">
                    <i
                      >Aucune option supplémentaire n'est définie sur cette
                      section</i
                    >
                  </h3>
                </div>

                <div
                  v-for="(question, questionIndex) in section.questions"
                  :key="question.id"
                >
                  <b-card border-variant="primary" class="option-card">
                    <b-row class="text-align-left">
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="8" class="d-flex flex-row">
                            <div class="mr-1" v-if="question.image_url">
                              <b-img
                                class="option-image"
                                width="200"
                                :src="question.image_url"
                              />
                            </div>

                            <div class="d-flex flex-column">
                              <div class="d-flex flex-row">
                                <h5>{{ question.title }}</h5>
                                <span class="ml-50" v-if="!question.is_active">
                                  <b-badge variant="light-secondary"
                                    >Brouillon
                                  </b-badge>
                                </span>
                                <span class="ml-50" v-if="question.choice_type">
                                  <b-badge variant="badge badge-primary">
                                    {{
                                      getChoiceType(question.choice_type)
                                    }}</b-badge
                                  >
                                </span>
                              </div>

                              <div class="description">
                                {{ question.description }}
                              </div>
                            </div>
                          </b-col>

                          <b-col cols="4" class="text-align-right">
                            <div>
                              <b-button-group>
                                <b-button
                                  variant="outline-primary"
                                  size="sm"
                                  @click="
                                    moveQuestion(section.id, question.id, 'up')
                                  "
                                  :disabled="questionIndex === 0"
                                >
                                  <feather-icon size="13" icon="ArrowUpIcon" />
                                </b-button>
                                <b-button
                                  variant="outline-primary"
                                  size="sm"
                                  @click="
                                    moveQuestion(
                                      section.id,
                                      question.id,
                                      'down'
                                    )
                                  "
                                  :disabled="
                                    questionIndex ===
                                    section.questions.length - 1
                                  "
                                >
                                  <feather-icon
                                    size="13"
                                    icon="ArrowDownIcon"
                                  />
                                </b-button>
                              </b-button-group>

                              <b-button-group class="ml-2">
                                <b-button
                                  variant="warning"
                                  size="sm"
                                  @click="
                                    cloneQuestion(sectionIndex, question.id)
                                  "
                                >
                                  <feather-icon size="13" icon="CopyIcon" />
                                </b-button>

                                <b-button
                                  variant="primary"
                                  size="sm"
                                  @click="
                                    showEditQuestionModal(
                                      sectionIndex,
                                      question.id
                                    )
                                  "
                                >
                                  <feather-icon size="13" icon="Edit2Icon" />
                                </b-button>

                                <b-button
                                  variant="danger"
                                  size="sm"
                                  @click="
                                    deleteQuestion(question.id, sectionIndex)
                                  "
                                >
                                  <feather-icon size="13" icon="TrashIcon" />
                                </b-button>
                              </b-button-group>
                            </div>
                          </b-col>

                          <b-col v-if="question.choices" cols="12">
                            <b-card
                              class="choice-card"
                              v-for="(choice, choiceIndex) in question.choices"
                              :key="choice.id"
                              border-variant="primary"
                            >
                              <div>
                                <b-row>
                                  <b-col cols="7">
                                    <div class="d-flex flex-row">
                                      <h5 class="mb-0">
                                        {{ choice.title }}
                                      </h5>
                                      <span
                                        class="ml-50"
                                        v-if="!choice.is_active"
                                      >
                                        <b-badge variant="light-secondary"
                                          >Brouillon
                                        </b-badge>
                                      </span>
                                      <span
                                        v-if="choice.has_inventory"
                                        class="ml-50"
                                      >
                                        <b-badge
                                          variant="badge badge-light-success"
                                        >
                                          {{
                                            getChoiceRemainingCount(
                                              choice.inventory_count,
                                              choice.remaining_inventory
                                            )
                                          }}
                                        </b-badge>
                                      </span>
                                    </div>
                                    <div
                                      class="text-tertiary description"
                                      style="color: #a6a4b0"
                                    >
                                      {{ choice.description }}
                                    </div>
                                  </b-col>

                                  <b-col
                                    cols="5"
                                    class="justify-content-end d-flex flex-row"
                                  >
                                    <div
                                      class="d-flex flex-column justify-content-center"
                                      style="height: 30px"
                                    >
                                      <h5
                                        v-if="choice.price"
                                        class="text-tertiary mb-0"
                                      >
                                        {{ getChoicePrice(choice.price) }} $
                                      </h5>
                                    </div>

                                    <div>
                                      <b-button-group class="ml-2">
                                        <b-button
                                          variant="outline-primary"
                                          size="sm"
                                          @click="
                                            moveChoice(
                                              section.id,
                                              question.id,
                                              choice.id,
                                              'up'
                                            )
                                          "
                                          :disabled="choiceIndex === 0"
                                        >
                                          <feather-icon
                                            size="13"
                                            icon="ArrowUpIcon"
                                          />
                                        </b-button>
                                        <b-button
                                          variant="outline-primary"
                                          size="sm"
                                          @click="
                                            moveChoice(
                                              section.id,
                                              question.id,
                                              choice.id,
                                              'down'
                                            )
                                          "
                                          :disabled="
                                            choiceIndex ===
                                            question.choices.length - 1
                                          "
                                        >
                                          <feather-icon
                                            size="13"
                                            icon="ArrowDownIcon"
                                          />
                                        </b-button>
                                      </b-button-group>
                                    </div>

                                    <div>
                                      <b-button-group class="ml-2">
                                        <b-button
                                          variant="primary"
                                          size="sm"
                                          @click="
                                            showEditChoiceModal(
                                              sectionIndex,
                                              question.id,
                                              choice.id
                                            )
                                          "
                                        >
                                          <feather-icon
                                            size="13"
                                            icon="Edit2Icon"
                                          />
                                        </b-button>

                                        <b-button
                                          variant="danger"
                                          size="sm"
                                          @click="
                                            deleteChoice(
                                              sectionIndex,
                                              question.id,
                                              choice.id
                                            )
                                          "
                                        >
                                          <feather-icon
                                            size="13"
                                            icon="TrashIcon"
                                          />
                                        </b-button>
                                      </b-button-group>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <AdditionalOptionChoiceModal
                                :ref="`edit-choice-modal-${question.id}-${choice.id}`"
                                :current-choice="choice"
                                action="update"
                                @edit="
                                  updateChoice(question.id, choice.id, $event)
                                "
                              />
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row v-if="question.choice_type !== 'text'">
                      <b-col cols="12" class="text-align-right">
                        <b-button
                          :class="question.choices.length === 0 ? 'mt-1' : ''"
                          variant="success"
                          size="sm"
                          @click="
                            showCreateChoiceModal(sectionIndex, question.id)
                          "
                        >
                          + Ajouter un choix
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                  <AdditionalOptionQuestionModal
                    :ref="`question-modal-${question.id}`"
                    :current-question="question"
                    action="update"
                    :available-packages="packages"
                    :image-url="question.image_url"
                    @edit="updateQuestion($event, question.id)"
                  />
                  <AdditionalOptionChoiceModal
                    :ref="'create-choice-modal-' + question.id"
                    @create="createChoice($event, question.id)"
                  />
                </div>
              </b-col>

              <b-col
                v-if="canEdit"
                cols="12"
                class="d-flex flex-row justify-content-end"
              >
                <b-button
                  variant="success"
                  @click="showCreateQuestionModal(sectionIndex)"
                  size="sm"
                >
                  + Ajouter une option
                </b-button>
              </b-col>
            </b-row>
          </b-collapse>
          <AdditionalOptionSectionModal
            :ref="`edit-section-modal-${sectionIndex}`"
            action="update"
            :section="section"
            @edit="editSection($event, sectionIndex)"
          />
        </b-card>
      </div>
      <AdditionalOptionQuestionModal
        ref="question-modal"
        :available-packages="packages"
        @create="createQuestion"
      />
    </b-col>
    <AdditionalOptionSectionModal ref="section-modal" @create="createSection" />
  </b-row>
</template>

<script>
import {
  BBadge,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BButtonGroup,
  BCollapse,
  VBToggle,
  BImg,
} from "bootstrap-vue";
import groupCreationConfig from "@core/data/groupCreationConfig.js";

import AdditionalOptionQuestionModal from "./AdditionalOptionQuestionModal.vue";
import AdditionalOptionSectionModal from "./AdditionalOptionSectionModal.vue";
import AdditionalOptionChoiceModal from "./AdditionalOptionChoiceModal.vue";

import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService";
import { GroupPackagesService } from "@/views/groups/services/GroupPackagesService.js";

export const packagesService = new GroupPackagesService();
export const additionalOptionService = new GroupAdditionalOptionService();

import { onMounted } from "@vue/composition-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { useGroupApi } from "@/modules/group/composables/use-group-api";
import { reactive, ref, nextTick } from "@vue/composition-api";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import _ from "lodash";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    BImg,
    BBadge,
    AdditionalOptionChoiceModal,
    AdditionalOptionSectionModal,
    AdditionalOptionQuestionModal,
    RequiredTextInputField,
  },
  props: {
    groupId: {
      type: String,
      required: false,
      default: "NOT_PROVIDED_BY_PARENT_COMPONENT",
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup(props, { emit }) {
    const { refOf, forceUpdate } = useApplicationContext();
    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    let sections = ref([]);
    let currentSectionIndex = ref(null);
    let currentQuestionIndex = ref(null);
    let packages = ref([]);
    let currentRole = ref(null);

    currentRole.value =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;

    const fetchPackages = async (groupId) => {
      if (
        props.groupId != null &&
        props.groupId != "NOT_PROVIDED_BY_PARENT_COMPONENT"
      ) {
        try {
          const { data } = await packagesService.getPackages(groupId);
          if (data && data.length > 0) {
            packages.value = data.map((p) => {
              return {
                value: p.id,
                label: p.name,
              };
            });
          }

          forceUpdate();
        } catch (e) {
          console.error(e);
          displayErrorMessage(
            "Une erreur est arrivée en essayant de retrouver les informations des forfaits"
          );
        }
      }
    };

    const fetchSections = async (groupId) => {
      if (
        props.groupId != null &&
        props.groupId != "NOT_PROVIDED_BY_PARENT_COMPONENT"
      ) {
        try {
          const { data } =
            await additionalOptionService.getAdditionalOptionSections(groupId);
          sections.value = data;
          forceUpdate();
        } catch (e) {
          console.error(e);
          displayErrorMessage(
            "Une erreur est arrivée en essayant de retrouver les informations d'options supplementaires"
          );
        }
      }
    };

    onMounted(() => {
      fetchPackages(props.groupId);
      fetchSections(props.groupId);
    });

    function showCreateQuestionModal(sectionIndex) {
      refOf("question-modal").$children[0].$children[0].show();
      currentSectionIndex = sectionIndex;
    }

    function showEditQuestionModal(sectionIndex, questionId) {
      refOf(`question-modal-${questionId}`)[0].$children[0].$children[0].show();
      currentSectionIndex = sectionIndex;
    }

    function showAddSectionModal() {
      refOf("section-modal").$children[0].$children[0].show();
    }

    function showEditSectionModal(currentIndex) {
      refOf(
        `edit-section-modal-${currentIndex}`
      )[0].$children[0].$children[0].show();
      currentSectionIndex = currentIndex;
    }

    function getPayload(value) {
      return Object.assign({}, value);
    }

    const createQuestion = (payload) => {
      let sectionId = sections.value[currentSectionIndex].id;
      return new Promise((resolve, reject) => {
        additionalOptionService
          .storeQuestion(props.groupId, sectionId, payload)
          .then((response) => {
            nextTick(() => {
              fetchSections(props.groupId);
              forceUpdate();
            });

            displaySuccessMessage("Option ajoutée avec succès");
            forceUpdate();

            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder l'option"
            );
            reject(true);
          });
      });
    };

    const createSection = async (payload) => {
      return new Promise((resolve, reject) => {
        additionalOptionService
          .storeSection(props.groupId, payload)
          .then((response) => {
            sections.value.push(response.data.data);
            displaySuccessMessage("Section ajoutée avec succès");
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder la section"
            );
            reject(true);
          });
      });
    };

    const editSection = (event, index) => {
      let payload = getPayload(event);
      const sectionId = sections.value[index].id;
      return new Promise((resolve, reject) => {
        additionalOptionService
          .updateSection(props.groupId, sectionId, payload)
          .then((response) => {
            sections.value[index] = payload;
            displaySuccessMessage("Section modifiée avec succès");
            forceUpdate();
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder la section"
            );
            reject(true);
          });
      });
    };

    const updateQuestion = (event, questionId) => {
      let payload = getPayload(event);
      let sectionId = sections.value[currentSectionIndex].id;
      return new Promise((resolve, reject) => {
        additionalOptionService
          .updateQuestion(props.groupId, sectionId, questionId, payload)
          .then((response) => {
            nextTick(() => {
              fetchSections(props.groupId);
              forceUpdate();
            });

            forceUpdate();
            displaySuccessMessage("Option modifiée avec succès");
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder l'option"
            );
            reject(true);
          });
      });
    };

    const cloneQuestion = (sectionIndex, questionId) => {
      let sectionId = sections.value[sectionIndex].id;
      return new Promise((resolve, reject) => {
        additionalOptionService
          .cloneQuestion(props.groupId, sectionId, questionId)
          .then((response) => {
            nextTick(() => {
              fetchSections(props.groupId);
              forceUpdate();
            });

            forceUpdate();
            displaySuccessMessage("Option dupliquée avec succès");
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder l'option"
            );
            reject(true);
          });
      });
    };

    const cloneSection = (sectionId) => {
      return new Promise((resolve, reject) => {
        additionalOptionService
          .cloneSection(props.groupId, sectionId)
          .then((response) => {
            nextTick(() => {
              fetchSections(props.groupId);
              forceUpdate();
            });

            forceUpdate();
            displaySuccessMessage("Section dupliquée avec succès");
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de dupliquer la section"
            );
            reject(true);
          });
      });
    };

    function resetCurrentIndexes() {
      currentSectionIndex = null;
      currentQuestionIndex = null;
    }

    function getQuestionIndexById(sectionIndex, questionId) {
      return sections.value[sectionIndex].questions.findIndex((x) => {
        return x.id === questionId;
      });
    }

    const createChoice = (event, questionId) => {
      let section = sections.value[currentSectionIndex];
      return new Promise((resolve, reject) => {
        additionalOptionService
          .storeChoice(props.groupId, section.id, questionId, getPayload(event))
          .then((response) => {
            nextTick(() => {
              fetchSections(props.groupId);
              forceUpdate();
            });

            displaySuccessMessage("Choix d'option ajouté avec succès");
            forceUpdate();

            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder le choix d'option"
            );
            reject(true);
          });
      });
    };

    const updateChoice = (questionId, choiceId, event) => {
      let payload = getPayload(event);
      let sectionId = sections.value[currentSectionIndex].id;
      return new Promise((resolve, reject) => {
        additionalOptionService
          .updateChoice(props.groupId, sectionId, questionId, choiceId, payload)
          .then((response) => {
            let questions = sections.value[currentSectionIndex].questions;
            let choiceIndex = questions[currentQuestionIndex].choices.findIndex(
              (x) => {
                return x.id === choiceId;
              }
            );
            questions[currentQuestionIndex].choices[choiceIndex] = payload;
            currentSectionIndex = null;
            forceUpdate();
            displaySuccessMessage(
              "Choix d'option supplémentaire modifié avec succès"
            );
            resolve(true);
          })
          .catch((error) => {
            console.error(error);
            displayErrorMessage(
              "Une erreur est arrivée en essayant de sauvegarder le choix de l'option supplémentaire"
            );
            reject(true);
          });
      });
    };

    function showCreateChoiceModal(sectionIndex, questionId) {
      resetCurrentIndexes();
      let modalRef = refOf("create-choice-modal-" + questionId)[0].$children[0]
        .$children[0];
      modalRef.show();
      currentSectionIndex = sectionIndex;
      currentQuestionIndex = getQuestionIndexById(sectionIndex, questionId);
    }

    function showEditChoiceModal(sectionIndex, questionId, choiceId) {
      resetCurrentIndexes();
      let modalRef = refOf(`edit-choice-modal-${questionId}-${choiceId}`)[0]
        .$children[0].$children[0];
      modalRef.show();
      currentSectionIndex = sectionIndex;
      currentQuestionIndex = getQuestionIndexById(sectionIndex, questionId);
    }

    const isCurrentUserAdmin = () => {
      return Roles.roleIsAllowed(currentRole.value.role, [
        Roles.ORGANIZATION_ADMIN.role,
        Roles.ORGANIZATION_COORDINATOR.role,
      ]);
    };

    const moveSection = async (sectionId, direction) => {
      let sectionIds = sections.value.map((x) => x.id);
      const index = sectionIds.findIndex((x) => x === sectionId);

      if (index === -1) {
        return;
      }

      setReorderedIds(direction, index, sectionId, sectionIds);

      await additionalOptionService.syncSectionOrdering(
        props.groupId,
        sectionIds
      );

      reorder(sectionIds, sections.value);
    };

    const moveQuestion = async (sectionId, questionId, direction) => {
      const section = sections.value.find((x) => x.id === sectionId);

      if (section && section.questions) {
        let questionIds = section.questions.map((x) => x.id);
        const index = questionIds.findIndex((x) => x === questionId);

        if (index === -1) {
          return;
        }

        setReorderedIds(direction, index, questionId, questionIds);

        await additionalOptionService.syncQuestionOrdering(
          props.groupId,
          sectionId,
          questionIds
        );

        reorder(questionIds, section.questions);
      }
    };

    const moveChoice = async (sectionId, questionId, choiceId, direction) => {
      const section = sections.value.find((x) => x.id === sectionId);
      const question = section.questions.find((x) => x.id === questionId);

      if (question && question.choices) {
        let choiceIds = question.choices.map((x) => x.id);
        const index = choiceIds.findIndex((x) => x === choiceId);

        if (index === -1) {
          return;
        }

        setReorderedIds(direction, index, choiceId, choiceIds);

        await additionalOptionService.syncChoiceOrdering(
          props.groupId,
          sectionId,
          questionId,
          choiceIds
        );

        reorder(choiceIds, question.choices);
      }
    };

    const setReorderedIds = (direction, index, currentId, itemIds) => {
      const newIndex = direction === "up" ? index - 1 : index + 1;
      itemIds.splice(index, 1);
      itemIds.splice(newIndex, 0, currentId);
    };

    const reorder = (reorderedIds, items) => {
      for (let i = 0; i < reorderedIds.length; i++) {
        const currentId = reorderedIds[i];
        let item = items.find((x) => x.id === currentId);
        item.display_order = i;
      }

      items.sort((a, b) => a.display_order - b.display_order);

      fetchSections(props.groupId);
    };

    return {
      sections,
      createSection,
      editSection,
      showEditQuestionModal,
      createQuestion,
      updateQuestion,
      isCurrentUserAdmin,
      showEditSectionModal,
      showAddSectionModal,
      showEditChoiceModal,
      createChoice,
      updateChoice,
      showCreateQuestionModal,
      showCreateChoiceModal,
      cloneQuestion,
      packages,
      moveQuestion,
      moveSection,
      moveChoice,
      cloneSection,
      fetchSections,
    };
  },
  methods: {
    getChoiceRemainingCount(totalCount, remainingCount) {
      let availabilityText = remainingCount > 1 ? "disponibles" : "disponible";
      return remainingCount + " / " + totalCount + " " + availabilityText;
    },
    getChoiceType(choiceType) {
      return groupCreationConfig.additionalOptionChoiceTypes.find(
        (x) => x.value === choiceType
      ).text;
    },
    deleteQuestion(questionId, sectionIndex) {
      this.$swal({
        title: "Suppression de l'option",
        text: "En supprimant cette option, vous supprimerez également tous les choix associés. Voulez-vous continuer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let sectionId = this.sections[sectionIndex].id;
            additionalOptionService
              .destroyQuestion(this.groupId, sectionId, questionId)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  text: "L'option a été supprimée.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.fetchSections(this.groupId);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$swal({
            icon: "error",
            title: "Erreur de suppression",
            text: `Une erreur s'est produite lors de la suppression de l'option. Veuillez réessayer.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
    deleteSection(sectionIndex) {
      this.$swal({
        title: "Suppression de la section",
        text: "En supprimant cette section, vous supprimerez aussi toutes les options et leurs choix associés. Voulez-vous continuer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let sectionId = this.sections[sectionIndex].id;
            additionalOptionService
              .destroySection(this.groupId, sectionId)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  text: "La section a été supprimée.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.fetchSections(this.groupId);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$swal({
            icon: "error",
            title: "Erreur de suppression",
            text: `Une erreur s'est produite lors de la suppression de la section. Veuillez réessayer.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
    deleteChoice(sectionIndex, questionId, choiceId) {
      this.$swal({
        title: "Suppression du choix",
        text: "Êtes-vous certain de vouloir supprimer ce choix?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let sectionId = this.sections[sectionIndex].id;
            additionalOptionService
              .destroyChoice(this.groupId, sectionId, questionId, choiceId)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  text: "Le choix a été supprimé.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.fetchSections(this.groupId);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$swal({
            icon: "error",
            title: "Erreur de suppression",
            text: `Une erreur s'est produite lors de la suppression du choix. Veuillez réessayer.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
    },
    getChoicePrice(price) {
      let value = 0.0;
      if (price) {
        value = price / 100;
      }
      return value.toFixed(2);
    },
  },
};
</script>

<style>
.collapse {
  padding: 2rem;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-white {
  color: white;
}

.choice-card {
  margin-top: 2rem;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Nombre de lignes max */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.section-description {
  padding-bottom: 2rem;
}

.option-card .card-body {
  padding: 2rem;
}

.option-image {
  height: 150px;
  object-fit: cover;
}

img {
  border-radius: 0.358rem;
}
</style>
