<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="modal-select2"
      title="Option supplémentaire"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @close="resetMedia"
      @cancel="resetMedia"
      @ok="submit"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Type de choix" label-for="choice_type">
              <v-select
                id="choice_type"
                placeholder="Liste des types de choix"
                ref="choice_type"
                v-model="question.choice_type"
                :options="choiceTypes"
                label="text"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <required-text-input-field
              v-model="question.title"
              name="title"
              label="Titre de l'option"
              label-for="title"
              placeholder="Titre de l'option"
            />
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="description"
              description="Limite de 1024 caractères"
            >
              <b-form-textarea
                rows="4"
                size="sm"
                maxlength="1024"
                v-model="question.description"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="mt-1">
              <b-form-checkbox
                v-model="question.is_required"
                name="is_required"
                switch
                inline
              >
                Réponse obligatoire
              </b-form-checkbox>
            </div>

            <div class="mt-1">
              <b-form-checkbox
                v-model="question.is_active"
                name="is_active"
                switch
                inline
              >
                Publié
              </b-form-checkbox>
            </div>

            <div class="mt-1">
              <b-form-checkbox
                v-model="question.is_traveler_specific"
                name="is_traveler_specific"
                switch
                inline
              >
                Choix distinct pour chaque voyageur
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mt-1">
              <b-form-checkbox
                v-model="question.has_custom_visibility"
                name="is-rtl"
                switch
                inline
              >
                Restreindre à des forfaits spécifiques
              </b-form-checkbox>
            </div>

            <b-form-group
              v-if="question.has_custom_visibility"
              label="Forfaits qui peuvent afficher cette option"
              label-for="packages"
              class="mt-1"
            >
              <v-select
                id="packages"
                ref="packages"
                :multiple="true"
                placeholder="Liste des forfaits"
                v-model="question.packages"
                :options="availablePackages"
                label="label"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h1 class="section-label mx-0 mt-2">
              Image de l'option supplémentaire
            </h1>
          </b-col>
          <b-col md="6">
            <b-form-file
              ref="file-upload"
              v-model="images.file1"
              :state="Boolean(images.file1)"
              placeholder="Veuillez sélectionner une image..."
              drop-placeholder="Déposer l'image ici..."
              browse-text="Parcourir"
              @change="uploadQuestionImage"
            ></b-form-file>
            <small class="form-text text-muted"
              >Poids maximal de l'image: 5 Mo</small
            >
            <div v-if="images && images.file1" class="mt-3">
              Nom du fichier: {{ images.file1 ? images.file1.name : "" }}
            </div>
          </b-col>
          <b-col v-if="imageUrl" md="6">
            <b-img :src="imageUrl" fluid></b-img>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BFormInvalidFeedback,
  BImg,
  BAlert,
} from "bootstrap-vue";

import groupCreationConfig from "@core/data/groupCreationConfig.js";
import { useGroupApi } from "@/modules/group/composables/use-group-api";

import Vue from "vue";
import { ValidationObserver } from "vee-validate";
import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";
import { required } from "@validations";
import { reactive, computed, ref } from "@vue/composition-api";
import _ from "lodash";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BAlert,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationObserver,
    RequiredTextInputField,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      groupCreationConfig,
    };
  },
  props: {
    currentQuestion: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
      default: "create",
    },
    availablePackages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    imageUrl: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { displayErrorMessage } = useToastNotification();
    const { uploadImage } = useGroupApi();

    let initialState = {
      title: null,
      description: null,
      is_required: false,
      is_active: true,
      is_traveler_specific: false,
      has_custom_visibility: false,
      choice_type: null,
      packages: [],
    };
    let images = reactive({});
    let mediaId = ref(null);

    const question = reactive({ ...initialState });
    const choiceTypes = computed(() => {
      return groupCreationConfig.additionalOptionChoiceTypes;
    });

    if (props.availablePackages && question.packages.length > 0) {
      let packages = _.cloneDeep(props.availablePackages).filter((x) => {
        return props.currentQuestion.packages.includes(x.value);
      });
      Vue.set(question, "packages", packages);
    }

    const resetMedia = () => {
      mediaId.value = null;
      images.file1 = null;
    };

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(question, props.currentQuestion);
        question.image_url = props.currentQuestion.image_url;
      } else {
        Object.assign(question, initialState);
        question.packages = [];
        question.choices = [];
      }
    };

    const submit = async (event) => {
      try {
        question.media_id = mediaId.value;
        question.packages = question.has_custom_visibility
          ? question.packages
          : [];
        if (props.action === "update") {
          emit("edit", question);
        } else {
          emit("create", question);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const uploadQuestionImage = async (event) => {
      let file = event.target.files[0];

      if (file) {
        let data = new FormData();
        data.append("name", file.name);
        data.append("type", "image");
        data.append("file", event.target.files[0]);

        try {
          let response = await uploadImage(data);
          let imageUrl = "";

          if (
            Object.hasOwn(response.data, "media_url") &&
            Object.hasOwn(response.data, "media_id")
          ) {
            imageUrl = response.data.media_url;
            mediaId.value = response.data.media_id;
          } else {
            imageUrl = response.headers.location;
          }

          Vue.set(question, "image_url", imageUrl);
        } catch (e) {
          console.error(e);
          displayErrorMessage("Le téléversement de l'image a échoué.");
          images.file1 = null;
          this.$refs["file-upload"].reset();
        }
      }
    };

    const { refFormObserver } = useValidatorsWithAutoFormDataCleanUp(question);

    return {
      submit,
      question,
      images,
      uploadQuestionImage,
      fillDataIfProvided,
      refFormObserver,
      required,
      resetMedia,
      mediaId,
      choiceTypes,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
