import axios from "@axios";

export class GroupAdditionalOptionService {
  constructor() {}
  async getAdditionalOptionSections(groupId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${groupId}/additional-option-sections`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async storeSection(groupId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/groups/${groupId}/additional-option-sections`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async updateSection(groupId, sectionId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/groups/${groupId}/additional-option-sections/${sectionId}`,
          payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async destroySection(groupId, sectionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/groups/${groupId}/additional-option-sections/${sectionId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async cloneSection(groupId, sectionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/groups/${groupId}/additional-option-sections/${sectionId}/clone`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async storeQuestion(groupId, sectionId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions`,
          payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async updateQuestion(groupId, sectionId, questionId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}`,
          payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async destroyQuestion(groupId, sectionId, questionId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async cloneQuestion(groupId, sectionId, questionId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}/clone`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async storeChoice(groupId, sectionId, questionId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}/choices`,
          payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async updateChoice(groupId, sectionId, questionId, choiceId, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}/choices/${choiceId}`,
          payload
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async destroyChoice(groupId, sectionId, questionId, choiceId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}/choices/${choiceId}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async syncSectionOrdering(groupId, sectionIds) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/groups/${groupId}/additional-option-sections/reorder`, {
          new_ordering: sectionIds,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  async syncChoiceOrdering(groupId, sectionId, questionId, choiceIds) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/${questionId}/choices/reorder`,
          {
            new_ordering: choiceIds,
          }
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  async syncQuestionOrdering(groupId, sectionId, questionIds) {
    return new Promise((resolve, reject) => {
      return axios
        .put(
          `/groups/${groupId}/additional-option-sections/${sectionId}/questions/reorder`,
          {
            new_ordering: questionIds,
          }
        )
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  async getAvailableAdditionalOptionSections(
    groupId,
    reservationId,
    publicAccessToken
  ) {
    let url = `/groups/${groupId}/reservations/${reservationId}/additional-option-sections`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async getReservationOptions(groupId, reservationId, publicAccessToken) {
    let url = `/groups/${groupId}/reservations/${reservationId}/options`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async getGroupAdditionalOptions(groupId, publicAccessToken) {
    let url = `/groups/${groupId}/options`;

    if (publicAccessToken != null) {
      url = `/groups/${id}/options?token=${publicAccessToken}`
    }

    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async storeAdditionalOptionAnswers(
    groupId,
    reservationId,
    payload,
    publicAccessToken
  ) {
    let url = `/groups/${groupId}/reservations/${reservationId}/options`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async updateAdditionalOptionAnswers(
    groupId,
    reservationId,
    payload,
    publicAccessToken
  ) {
    let url = `/groups/${groupId}/reservations/${reservationId}/options`;

    if (publicAccessToken) {
      url += `?token=${publicAccessToken}`;
    }

    return new Promise((resolve, reject) => {
      axios
        .put(url, {
          answers: payload,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
}
